import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OrganizationListGetter } from './organization-list-getter';
import { RegisterService } from './register.service';
import { RegisterComponentChildViews } from './register-component-child-views';
import { CountryListGetter } from './country-list-getter';
import { DateFormatListGetter } from './date-format-list-getter';
import { MessageModalMode, MessageModalOptions } from '../../abq-common/message-modal/message-modal-types';
import { MessageModalComponent } from '../../abq-common/message-modal/message-modal.component';
import { SpinnerModalComponent } from '../../abq-common/spinner-modal/spinner-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ArraySort } from '../../abq-common/array-sort';
import { UserCatalogService } from '@shared/services/http/user-catalog/user-catalog.service';
import { Country, Format, Organization, Separator } from '@shared/services/http/user-catalog/user-catalog-types';
import { TeamDTO } from '@shared/services/http/team-dto';
import { TeamsService } from '@shared/services/team/teams.service';
import { Teams } from '@shared/models/teams';
import { RegisterBasicUser, RegisterData } from './register-data';
import { FormError, GeneralError } from './response-with-success.interceptor';

@Component({
    selector: 'abq-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, RegisterComponentChildViews {

    public submitted = false;
    public registerForm: UntypedFormGroup;
    public organizations: Organization[];
    public countries: Country[];
    public formats: Format[];
    public separators: Separator[];
    public teams: Teams;
    public teamNames: string[];

    @ViewChild(MessageModalComponent) modalComponent: MessageModalComponent;
    @ViewChild(SpinnerModalComponent) spinnerModalComponent: SpinnerModalComponent;

    private readonly organizationListGetter: OrganizationListGetter;
    private readonly countryListGetter: CountryListGetter;
    private readonly dateFormatListGetter: DateFormatListGetter;
    public username = 'noname';
    public email = 'noname@noname.com';

    constructor(
        protected formBuilder: UntypedFormBuilder,
        private readonly router: Router,
        private readonly registerService: RegisterService,
        private readonly userCatalogService: UserCatalogService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly teamsService: TeamsService
    ) {
        this.organizationListGetter = new OrganizationListGetter(this.userCatalogService, this);
        this.countryListGetter = new CountryListGetter(this.userCatalogService, this);
        this.dateFormatListGetter = new DateFormatListGetter(this.userCatalogService, this);
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(params => {
            this.username = params.username;
            this.email = params.email;
        });
        this.registerForm = this.formBuilder.group({
            password: ['', [Validators.required]],
            organization_code: ['', [Validators.required]],
            country: ['', [Validators.required]],
            simple_date_format: ['', [Validators.required]],
            separator: ['', [Validators.required]],
            four_digit_year: [''],
            teams: []
        });

        this.organizationListGetter.getOrganizations();
        this.countryListGetter.getCountries();
        this.dateFormatListGetter.getDateFormats();
        this.teamsService.getTeams$().subscribe((teamDTOs: TeamDTO[]) => {
            teamDTOs.sort(new ArraySort().dynamicSort('name'));
            this.teams = Teams.createFromDTOs(teamDTOs);
            this.teamNames = this.teams.getTeamNames();
        });
    }

    // Convenience getter for easy access to form fields.
    get formCtr() {
        return this.registerForm.controls;
    }

    updateOrganizations(organizations: Organization[]): void {
        this.organizations = organizations;
    }

    updateCountries(countries: Country[]): void {
        this.countries = countries;
    }

    updateDateFormats(formats: Format[], separators: Separator[]): void {
        this.formats = formats;
        this.separators = separators;
    }

    public register() {
        this.submitted = true;
        if (this.registerForm.invalid === false) {
            this.spinnerModalComponent.show();
            const selectedTeamIds: number[] = this.teams.getTeamIdsFromNames(this.formCtr.teams.value);
            const registerBasicUser = new RegisterBasicUser(this.username, this.email, this.formCtr.password.value);
            const registerData = new RegisterData(registerBasicUser, this.formCtr.organization_code.value,
                this.formCtr.country.value, this.formCtr.simple_date_format.value, this.formCtr.separator.value,
                this.formCtr.four_digit_year.value, selectedTeamIds);
            this.registerService.register$(registerData).subscribe({
                next: () => this.showRequestProcessed(this.email),
                error: (error) => {
                    if (error instanceof FormError){
                        return this.manageErrorResponseWithFormErrors(error.formErrors);
                    }
                    if (error instanceof GeneralError) {
                        return this.manageErrorResponseWithErrors(error.message);
                    }
                    return this.manageErrorResponseWithErrors(error.toString());
                }
            });
        }
    }

    private showRequestProcessed(email: string) {
        this.spinnerModalComponent.hide();
        const dialogOptions: MessageModalOptions = {
            title: 'Request processed',
            text: 'A request has been sent to the administrator. When he accepts it, an email with a ' +
                'confirmation link will be sent to your ' + email + ' address, and you will be able to sign ' +
                'in with it.',
            mode: MessageModalMode.CONTINUE,
            hideTimesButton: true,
            continueAction: () => {
                // noinspection JSIgnoredPromiseFromCall
                this.router.navigate(['/login']);
            }
        };
        this.modalComponent.show(dialogOptions);
    }

    private manageErrorResponseWithErrors(error: string) {
        this.spinnerModalComponent.hide();
        this.registerForm.markAsTouched();
        this.registerForm.setErrors({server_error: error});
    }

    private manageErrorResponseWithFormErrors(formErrors: { [key: string]: string }) {
        this.spinnerModalComponent.hide();
        for (const field in formErrors) {
            if (Object.prototype.hasOwnProperty.call(formErrors, field)) {
                const control = this.registerForm.get(field);
                if (control) {
                    control.markAsTouched();
                    control.setErrors({server_error: formErrors[field]});
                } else {
                    this.registerForm.markAsTouched();
                    this.registerForm.setErrors({server_error: formErrors[field]});
                }
            }
        }
    }
}
