import { TeamDTO } from "@shared/services/http/team-dto";

export class Team {
    private readonly id: number;
    private readonly name: string;
    private readonly contractor: boolean;

    constructor(id: number, name: string, isContractor: boolean) {
        this.id = id;
        this.name = name;
        this.contractor = isContractor;
    }

    static createFromDTO(teamDTO: TeamDTO): Team {
        return new Team(teamDTO.id, teamDTO.name, teamDTO.contractor);
    }

    getId(): number {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    isContractor(): boolean {
        return this.contractor;
    }
}
