import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type onChangeCallbackType = ((quantity: string[]) => void);
type onTouchedCallbackType = (() => void);

@Component({
    selector: 'abq-form-check-dropdown',
    templateUrl: './form-check-dropdown.component.html',
    styleUrls: ['./form-check-dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: FormCheckDropdownComponent
        }
    ]
})
export class FormCheckDropdownComponent implements AfterViewInit, ControlValueAccessor {
    optionsStored: string[] = [];
    @Input() set options(options: string[]) {
        this.optionsStored = options;
        this.selected = {};
    }
    @Output() optionsChangedEvent: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('showValue') checkDropdownRef: ElementRef;
    private checkDropdown$: JQuery<ElementRef>;

    selected: {[key: string]: boolean} = {};
    allSelected: boolean = false;
    placeholder: string = '- Select value -';

    onChange: onChangeCallbackType = (): void => {
        return;
    };
    onTouched: onTouchedCallbackType = (): void => {
        return;
    };
    touched: boolean = false;


    ngAfterViewInit(): void {
        this.checkDropdown$ = $(this.checkDropdownRef.nativeElement);
        this.checkDropdown$.dropdown();
    }

    selectOption(event: MouseEvent, option: string): void {
        this.markAsTouched();
        this.selected[option] = !this.selected[option];
        this.onChange(this.getSelectedOptionsList());
        this.checkIfAllSelected();
        this.updatePlaceholder();
        this.optionsChangedEvent.emit();
        event.stopPropagation();
    }

    inputClicked(): void {
        return;
    }

    writeValue(quantity: string[]): void {
        this.selected = {};
        if (quantity) {
            for (const option of quantity) {
                this.selected[option] = true;
            }
        }
    }

    registerOnChange(onChange: onChangeCallbackType): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: onTouchedCallbackType): void {
        this.onTouched = onTouched;
    }

    markAsTouched(): void {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    selectAll(event: MouseEvent): void {
        this.allSelected = !this.allSelected;
        for (const option of this.optionsStored) {
            this.selected[option] = this.allSelected;
        }
        this.onChange(this.getSelectedOptionsList());
        this.updatePlaceholder();
        this.optionsChangedEvent.emit();
        event.stopPropagation();
    }

    private getSelectedOptionsList(): string[] {
        return Object.keys(this.selected).filter((key: string) => this.selected[key]);
    }

    private checkIfAllSelected() {
        this.allSelected = this.getSelectedOptionsList().length === this.optionsStored.length;
    }

    private updatePlaceholder() {
        if (this.getSelectedOptionsList().length === 0) {
            this.placeholder = '- Select value -';
        } else {
            this.placeholder = `- ${this.getSelectedOptionsList().length} selected -`;
        }
    }
}
