import { Injectable } from '@angular/core';
import {
    HttpContextToken,
    HttpErrorResponse,
    HttpEvent,
    HttpEventType,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export const ResponseWithSuccessToken = new HttpContextToken<boolean>(() => false);

export type ResponseWithSuccess = { success: boolean, errors: string, form_errors: { [key: string]: string } };

export class GeneralError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'GeneralError';
    }
}

export class FormError extends Error {
    formErrors: { [key: string]: string };

    constructor(message: string, formErrors?: { [key: string]: string }) {
        super(message);
        this.name = 'FormError';
        this.formErrors = formErrors;
    }
}

@Injectable()
export class ResponseWithSuccessInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (this.doesInterceptionApply(request)) {
            return this.checkSuccessFalseToThrowError(next, request);
        }
        return next.handle(request);
    }

    private checkSuccessFalseToThrowError(next: HttpHandler, request: HttpRequest<unknown>) {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                const formError: FormError = new FormError(error.error.errors, error.error.form_errors);
                return throwError(formError);
            }),
            tap((httpEvent: HttpEvent<unknown>) => {
                if (httpEvent.type === HttpEventType.Response) {
                    const body = httpEvent.body as ResponseWithSuccess;
                    if (!body.success) {
                        throw new GeneralError(body.errors);
                    }
                }
            })
        );
    }

    private doesInterceptionApply(request: HttpRequest<unknown>) {
        return request.context.get(ResponseWithSuccessToken);
    }
}
