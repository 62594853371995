import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[abqUppercase]'
})
export class UppercaseDirective {
    constructor(private readonly control: NgControl) {}

    @HostListener('input', ['$event.target.value'])
    onInputChange(value: string): void {
        this.control.control?.setValue(value.toUpperCase(), { emitEvent: false });
    }
}
