import { HttpParams } from '@angular/common/http';

export class RegisterBasicUser {
    private readonly username: string;
    private readonly emailAddress: string;
    private readonly password: string;

    constructor(username: string, emailAddress: string, password: string) {
        this.username = username;
        this.emailAddress = emailAddress;
        this.password = password;
    }

    appendToHttpParams(params: HttpParams): HttpParams {
        params = params.append('username', this.username);
        params = params.append('email', this.emailAddress);
        params = params.append('password', this.password);
        return params;
    }
}

export class RegisterData {
    private readonly registerBasicUser: RegisterBasicUser;
    private readonly organization: number;
    private readonly country: string;
    private readonly format: string;
    private readonly separator: string;
    private readonly fourDigitYear: boolean;
    private readonly teams: number[];


    constructor(registerBasicUser: RegisterBasicUser, organization: number, country: string,
                format: string, separator: string, fourDigitYear: boolean, teams: number[]
    ) {
        this.registerBasicUser = registerBasicUser;
        this.organization = organization;
        this.country = country;
        this.format = format;
        this.separator = separator;
        this.fourDigitYear = fourDigitYear;
        this.teams = teams;
    }

    createHttpParams(): HttpParams {
        let params = new HttpParams();
        params = this.registerBasicUser.appendToHttpParams(params);
        params = params.append('organization_code', this.organization.toString());
        params = params.append('country', this.country);
        params = params.append('simple_date_format', this.format);
        params = params.append('separator', this.separator);
        params = params.append('four_digit_year', this.fourDigitYear ? 'True' : 'False');
        for (const team of this.teams) {
            params = params.append('team', team.toString());
        }
        return params;
    }
}
