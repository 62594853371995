import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'abq-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements AfterViewInit {

    elementsStored: string[] = [];
    @Input() public set elements(elements: string[]) {
        this.elementsStored = elements;
        this.searchStringIfDefined();
    }
    @Input() icon: string = 'fa-check';
    @Input() name: string = 'Dropdown';
    @Input() searchBox: boolean = false;
    @Input() disabled: boolean = false;
    @Output() elementSelectedEvent: EventEmitter<string> = new EventEmitter<string>();
    @ViewChild('dropdown') dropdownRef: {nativeElement: HTMLElement;};
    @ViewChild('searchInput') searchInput: ElementRef;
    public hiddenBySearch: {[name: string]: boolean} = {};
    private dropDown$: JQuery<HTMLElement>;
    private dropdownShown: boolean = false;

    ngAfterViewInit(): void {
        this.dropDown$ = $(this.dropdownRef.nativeElement)
        this.dropDown$.find('.dropdown-toggle').dropdown();
        this.dropDown$.on('shown.bs.dropdown', () => {
            this.dropdownShown = true;
        });
        this.dropDown$.on('hidden.bs.dropdown', () => {
            this.dropdownShown = false;
        });
    }

    elementClicked(value: string): void {
        this.elementSelectedEvent.emit(value);
        this.toggleDropdown();
    }

    searchString(value: string): void {
        for (const element of this.elementsStored) {
            const found: boolean = value === '' || (element?.toLowerCase().indexOf(value.toLowerCase()) >= 0);
            this.hiddenBySearch[element] = !found;
        }
    }

    dropdownClicked(): void {
        if (!this.dropdownShown) {
            // This double show is a workaround to correctly show wide menus.
            this.dropDown$.find('.dropdown-toggle').dropdown('show');
            setTimeout(() => {
                this.dropDown$.find('.dropdown-toggle').dropdown('show');
            });
        }
    }

    private toggleDropdown(): void {
        this.dropDown$.dropdown('toggle');
    }

    private searchStringIfDefined(): void {
        if (this.searchInput?.nativeElement?.value) {
            this.searchString(this.searchInput.nativeElement.value);
        }
    }
}
