import { Team } from '@shared/models/team';
import { TeamDTO } from '@shared/services/http/team-dto';

export class Teams {

    private readonly teams: Team[];

    constructor(teams: Team[]) {
        this.teams = teams;
    }

    static createFromDTOs(teams: TeamDTO[]): Teams {
        return new Teams(teams.map((team: TeamDTO) => Team.createFromDTO(team)));
    }

    getTeamIdsFromNames(names: string[] | null): number[] {
        if (names === null || names.length === 0) {
            return [];
        }
        return this.teams.filter((team: Team) => names.includes(team.getName())).map((team: Team) => team.getId());
    }

    getTeamNames(): string[] {
        return this.teams.map((team: Team) => team.getName());
    }
}
