<input #showValue type="text" class="dropdown-toggle form-control cursor-default"
       data-toggle="dropdown" placeholder="{{ placeholder }}" readonly
       (click)="inputClicked()">
<span class="fa fa-xs fa-chevron-down position-absolute"></span>
<div class="dropdown-menu w-100">
    <div class="scrollable-menu position-relative">
        <button class="dropdown-item py-1 px-2 font-size-09" type="button" (click)="selectAll($event)">
            <i class="fa fa-check text-primary mr-2" [ngClass]="{ 'd-transparent': !allSelected }"></i>
            Select All
        </button>
        <div class="dropdown-divider"></div>
        <ng-container *ngFor="let option of optionsStored">
            <button class="dropdown-item py-1 px-2 font-size-09" type="button" (click)="selectOption($event, option)">
                <i class="fa fa-check text-primary mr-2" [ngClass]="{ 'd-transparent': !selected[option] }"></i>
                {{ option }}
            </button>
        </ng-container>
    </div>
</div>
