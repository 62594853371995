<div #dropdown class="btn-group">
    <button class="btn btn-outline-primary btn-sm mx-1 py-0 dropdown-toggle btn-block" type="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="disabled"
            (click)="dropdownClicked()">
        <i class="fa {{ icon }}"></i>
        {{ name }}
    </button>
    <div class="dropdown-menu dropdown-menu-right">
        <div *ngIf="searchBox === true" class="input-group px-2">
            <span class="input-group-prepend w-100">
                <i class="fa fa-search position-absolute"></i>
                <input #searchInput id="search-input" class="form-control py-2 border" placeholder="Search..."
                       (input)="searchString( searchInput.value )">
           </span>
        </div>
        <ng-scrollbar class="scrollable-menu position-relative"
                      [track]="'vertical'" [pointerEventsMethod]="'scrollbar'"
                      [autoHeightDisabled]="false" [autoWidthDisabled]="false"
                      (click)="$event.stopPropagation()">
            <ng-container *ngFor="let value of elementsStored">
                <button *ngIf="!hiddenBySearch[value]" class="dropdown-item py-1"
                        (click)="elementClicked( value )">
                    {{ value }}
                </button>
            </ng-container>
        </ng-scrollbar>
    </div>
</div>
