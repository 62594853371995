import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { RegisterData } from './register-data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseWithSuccessToken } from './response-with-success.interceptor';

export interface RegisterServiceResponse {
    success: boolean;
    errors: string;
    form_errors?: { [key: string]: string };
}

@Injectable({
    providedIn: 'root'
})
export class RegisterService {
    public readonly URL = '/abaqus/rest_api/webapp/auth/register_user_ask/';

    constructor(private readonly http: HttpClient) {
    }

    register$(data: RegisterData): Observable<void> {
        const context = new HttpContext().set(ResponseWithSuccessToken, true);
        const options = {context, params: data.createHttpParams()};
        return this.http.get<RegisterServiceResponse>(this.URL, options).pipe(
            map(() => void (0)),
        );
    }
}

